/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { headerConfig } from '@slipchip/phx-sso-header'

import { withStyles } from '../utils'

const TalisCloudLogo = (props) => {
  const classes = withStyles()
  const { TALIS_CLOUD_LOGO: logoFile } = headerConfig
  return (
    <img
      alt="Logo"
      src={logoFile}
      className={classes.talisCloudLogo}
      {...props}
    />
  )
}

export default TalisCloudLogo
