import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LanguageProvider } from "@slipchip/common-ui-components";

import requestLanguages, { DEFAULT_LANGUAGE } from '../utils/readLanguages';
import Loading from './Loading';

const LanguageProviderContainer = ({ locale, children }) => {
  const [languages, setLanguages] = useState({});
  const [messages, setMessages] = useState({});
  useEffect(async () => {
    // eslint-disable-next-line no-underscore-dangle
    const _languages = await requestLanguages();
    setLanguages(_languages);
  }, []);
  useEffect(() => {
    setMessages(languages[locale] || {});
  }, [locale, languages]);
  return (
    <>
      {!!Object.keys(messages).length ? (
        <LanguageProvider locale={locale} strings={messages}>
          {children}
        </LanguageProvider>
      ) : <Loading />}
    </>
  );
};

LanguageProviderContainer.propTypes = {
  locale: PropTypes.string,
  children: PropTypes.node.isRequired,
};

LanguageProviderContainer.defaultProps = {
  locale: DEFAULT_LANGUAGE,
};

export default LanguageProviderContainer;

