/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { headerConfig } from '@slipchip/phx-sso-header'

import { withStyles } from '../utils'

const TalisCloudLogo = (props) => {
  const classes = withStyles()
  const { CLIENT_LOGO: logoFile } = headerConfig
  return (
    <div className={classes.clientLogoWrapper}>
      <img
        alt="Logo"
        src={logoFile}
        className={classes.clientLogo}
        {...props}
      />
    </div>
  )
}

export default TalisCloudLogo
