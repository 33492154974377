import { axios } from '@slipchip/phx-sso-header'

export const DEFAULT_LANGUAGE = 'en'
const SupportedLanguages = [DEFAULT_LANGUAGE, 'fr']

// function that perform the request
const requestGetLang = async (lang) => {
  const response = await axios.get(`/lang/${lang}.json`)
  if (response.data) {
    return response.data
  }
  return null
}

const requestLanguages = async () => {
  const languages = {}
  for (let i = 0; i < SupportedLanguages.length; i += 1) {
    const lang = SupportedLanguages[i]
    // eslint-disable-next-line no-await-in-loop
    const jsonLang = await requestGetLang(lang)
    languages[lang] = jsonLang
  }
  return languages
}

export default requestLanguages
