import React, { useEffect } from 'react'
import { headerConfig, CookieStorage } from '@slipchip/phx-sso-header'
import { useLocation, useHistory } from 'react-router-dom'

import CognitoForm from './CognitoForm'
import OIDCLinks from './OIDCLinks'
import PageLayout from '../PageLayout'

const useQuery = () => new URLSearchParams(useLocation().search)

const headerCookieStorage = new CookieStorage()

const LoginPage = () => {
  
  const history = useHistory();
  const query = useQuery();
  const { USER_LOGIN } = headerConfig;

  useEffect(() => {
    const code = query.get('code');
    const state = query.get('state');
    const accessToken = headerCookieStorage.get('accessToken');
    const accessExpireTime = headerCookieStorage.get('accessExpireTime');
    const isTokenValid = Date.now() < accessExpireTime;
    if (code && !accessToken) {
      history.push(`/logged-in?code=${code}&state=${state}`);
    } else if (accessToken && isTokenValid) {
      history.push('/logged-in?redirect=true');
    }
  }, []);

  return (
    <PageLayout>
      {!!USER_LOGIN && <CognitoForm />}
      {false && <OIDCLinks />}
    </PageLayout>
  )
}

export default LoginPage
