/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { axios, headerConfig, CookieStorage } from '@slipchip/phx-sso-header'
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  FilledInput,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { Alert } from '@slipchip/phx-sw-alerts'
import { formatMessage, useCommonIntl as useIntl } from '@slipchip/common-ui-components';

import { withStyles } from '../../utils'

const cookieStorage = new CookieStorage();

const CognitoForm = () => {
  const classes = withStyles()
  const intl = useIntl();
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [sso, setSso] = useState({});
  const [showPassword, setShowPassword] = useState(false)
  const [usernameError, setUsernameError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)
  const [errorText, setErrorText] = useState('')
  const history = useHistory()
  const [usernameErrorText, setUsernameErrorText] = useState(null)

  useEffect(() => {
    // Get the organization config so we know if it has SSO options available
    async function fetchOrganizationData() {
      const resp = await axios.get(
        '/api/auth/v1/config',
        { headers: { 'Content-Type': 'application/json' } },
      );
      const ssoConfig = resp?.data?.config?.SSO || {};
      setSso(ssoConfig);
      cookieStorage.set('sso', JSON.stringify(ssoConfig));
    }
    fetchOrganizationData();
  }, []);

  const handleUsernameChange = (e) => {
    setUsernameError(false)
    setUsernameErrorText(null)
    setUsername(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPasswordError(false)
    setPasswordError(null)
    setPassword(e.target.value)
  }

  const handleSsoLogin = (ssoDetails) => {
    // Construct the Cognito AUTH end point and redirect
    const url = new URL(`${ssoDetails.url}/authorize`);

    const crypto = window.crypto || window.msCrypto;
    const randomArray = new Uint32Array(1);
    const state = crypto.getRandomValues(randomArray)[0].toString(36).replace(/[^a-z]+/g, '').slice(0, 10);  // Generate a random string
    cookieStorage.set('cognito_state', state);
    url.search = new URLSearchParams({
      identity_provider: ssoDetails.identity_provider,
      redirect_uri: ssoDetails.redirect_uri,
      client_id: ssoDetails.client_id,
      response_type: 'CODE',
      scope: 'aws.cognito.signin.user.admin email openid profile',
      state,
    });
    window.location = url;
  }

  const handleLogin = async (e) => {
    setErrorSnackOpen(false)
    e.preventDefault()
    const resp = await axios.post(
      headerConfig.COGNITO_ENDPOINT,
      {
        username,
        password,
      },
      { headers: { 'Content-Type': 'application/json' } },
    )
    const { data } = resp
    if (data.success) {
      if (data.session) {
        cookieStorage.set('challenge', data.session)
        cookieStorage.set('user_id', data.user_id)
        history.push(`/confirm?username=${username}`)
      } else {
        const { access_token, refresh_token, expires_in } = data
        const expiresIn = expires_in * 1000
        cookieStorage.set('accessToken', access_token)
        cookieStorage.set('refreshToken', refresh_token)
        cookieStorage.set('expiresIn', expiresIn)
        cookieStorage.set('accessExpireTime', Date.now() + expiresIn)
        history.push('/logged-in?redirect=true&cognito=true')
      }
    } else {
      // display error message
      setErrorText(intl.formatMessage({ id:`serverErrorCode.${data.error_code}` }))
      setErrorSnackOpen(true)
    }
  }

  const forgotPassword = async (e) => {
    e.preventDefault()
    setErrorSnackOpen(false)
    if (username) {
      const url = `${headerConfig.REDIRECT_URI}/api/users/v1/forgot-password`
      const resp = await axios.post(
        url,
        {
          username,
        },
        { headers: { 'Content-Type': 'application/json' } },
      )
      const { data } = resp
      if (data.success) {
        history.push(`reset-password?name=${username}`)
      } else {
        if (data.error_code === 400 && data.field_errors) {
          if (data.field_errors.username) {
            setUsernameErrorText(
              intl.formatMessage({ id: `fieldError.validator.${data.field_errors.username.validator}`}),
            )
          }
        } else {
          setErrorText(intl.formatMessage({ id: `serverErrorCode.${data.error_code}`}))
          setErrorSnackOpen(true)
        }
        setUsernameError(true)
      }
    } else {
      setUsernameError(true)
      setUsernameErrorText(
        intl.formatMessage({ id: 'loginForm.validation.username.required'}),
      )
    }
  }

  return (
    <div className={classes.CognitoForm}>
      <form className={classes.cognitoLoginForm} noValidate autoComplete="off">
        <div className={classes.cognitoLoginInput}>
          <FormControl
            fullWidth
            margin="normal"
            error={usernameError}
            variant="filled">
            <InputLabel
              htmlFor="username"
              className={classes.cognitoMUIInputLabel}>
              {formatMessage('loginForm.userName')}
            </InputLabel>
            <FilledInput
              id="username"
              value={username}
              onChange={handleUsernameChange}
              className={classes.cognitoMUIInput}
              size="small"
              fullWidth
            />
            {usernameError && Boolean(usernameErrorText) && (
              <FormHelperText id="username-helper-text">
                {usernameErrorText}
              </FormHelperText>
            )}
          </FormControl>
        </div>
        <div className={classes.cognitoLoginInput}>
          <FormControl
            fullWidth
            margin="normal"
            error={passwordError}
            variant="filled">
            <InputLabel
              htmlFor="password"
              className={classes.cognitoMUIInputLabel}>
              {formatMessage('loginForm.password')}
            </InputLabel>
            <FilledInput
              id="password"
              className={classes.cognitoMUIInput}
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={handlePasswordChange}
              size="small"
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <div className={classes.cognitoForgotPasswordButtonWrapper}>
          <Button
            href="#"
            color="primary"
            onClick={forgotPassword}
            data-qa="forgot-btn"
            className={classes.cognitoForgotPasswordButton}>
            {formatMessage('loginForm.forgotPassword')}
          </Button>
        </div>
        <div className={classes.cognitoLoginButtonWrapper}>
          <Button
            type="submit"
            onClick={handleLogin}
            disabled={!password || !username || usernameError || passwordError}
            className={classes.cognitoLoginButton}
            data-qa="login-btn"
            variant="contained">
            {formatMessage('loginForm.loginBtn')}
          </Button>
        </div>
        {sso.Azure && (
          <div className={classes.cognitoLoginButtonWrapper}>
            <Button
              onClick={() => {handleSsoLogin(sso.Azure)}}
              className={classes.cognitoLoginButton}
              style={{backgroundColor: '#0078d4'}}
              data-qa="sso-login-btn"
              variant="contained">
              {formatMessage('loginForm.azureLoginButton')}
            </Button>
          </div>
        )}
      </form>
      <Alert
        error
        open={errorSnackOpen}
        autoHideDuration={600000}
        className={classes.cognitoErrorSnackbar}
        onClose={() => setErrorSnackOpen(false)}
        message={errorText}
      />
    </div>
  )
}

export default CognitoForm
