import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { formatMessage } from '@slipchip/common-ui-components';

const useStyles = makeStyles((theme) => ({
  App: {
    background: '#fff',
    width: '100vw',
    height: '100vh',
    display: 'flex',
  },
  PageWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  PageNotFoundLogo: {
    padding: 60,
    display: 'flex',
    width: 200,
  },
  TalisCloudLogo: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 30,
  },
  PageNotFoundMessageSection: {
    justifyContent: 'center',
    display: 'flex',
  },
  PageNotFoundMessageWrapper: {
    marginTop: 160,
    width: 420,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },
  PageNotFoundMessageTitle: {
    ...theme.typography.h4,
    fontFamily: 'Mulish',
    fontWeight: 400,
    lineHeight: 1.235,
    opacity: '0.8',
    marginBottom: 14,
    letterSpacing: '-0.06px',
  },
  PageNotFoundMessageSubtitle: {
    ...theme.typography.subtitle1,
    fontFamily: 'Mulish',
    opacity: '0.6',
  },
}));

const PageNotFound = () => {
  const classes = useStyles();
  return (
    <div className={classes.App}>
      <div className={classes.PageWrapper} data-qa="page-wrapper">
        <div>
          <div className={classes.PageNotFoundLogo}>
            <img alt="Logo" src="/assets/Talis_Logo_Dark.svg" className={classes.TalisCloudLogo} width="200" />
          </div>
        </div>
        <Box className={classes.PageNotFoundMessageSection}>
          <Box className={classes.PageNotFoundMessageWrapper}>
            <Box className={classes.PageNotFoundMessageTitle}>
              {formatMessage('pageNotFound.title')}
            </Box>
            <Box className={classes.PageNotFoundMessageSubtitle}>
              {formatMessage('pageNotFound.message')}
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default PageNotFound;
