import clsx from 'clsx'
import React from 'react'
import { CircularProgress } from '@material-ui/core'
import Header from '@slipchip/phx-sso-header'

import { withStyles } from '../../utils'

const LogoutPage = () => {
  const classes = withStyles()
  return (
    <div className={clsx(classes.App, classes.flexColumn)}>
      <Header clearCode render={false} logoutUserView />
      <div className={classes.marginAuto}>
        <CircularProgress color="secondary" size={80} />
      </div>
    </div>
  )
}

export default LogoutPage
