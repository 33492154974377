import React from 'react'
import PropTypes from 'prop-types'
import { headerConfig } from '@slipchip/phx-sso-header'
import { formatMessage } from '@slipchip/common-ui-components';
import { Box, Grid, Button } from '@material-ui/core'
import { withStyles } from '../utils'
import TalisCloudLogo from './TalisCloudLogo'
import ClientLogo from './ClientLogo'

const PageLayout = ({ children }) => {
  const classes = withStyles()
  const { CLIENT_LOGO } = headerConfig
  const version = '3.4.0';
  return (
    <div className="App">
      <div className={classes.App}>
        <Grid container component="main" direction="row">
          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.PageWrapper} data-qa="page-wrapper">
              <div className={classes.LoginPage} data-qa="form-wrapper">
                {!!CLIENT_LOGO && <ClientLogo />}
                <div className={classes.formPaper}>
                  <div className={classes.TalisOneLogoWrapper}>
                    <TalisCloudLogo width="250" />
                  </div>
                  {children}
                </div>
              </div>
              <Box className={classes.copyrightWrapper} data-qa="copyrights-wrapper">
                <Box data-qa="copyrights-string">{formatMessage('talis.copyright')}</Box>
                <Box>
                  <Button href="https://talisbio.com/privacy/" data-qa="privacy">{formatMessage('talis.privacy')}</Button>
                  <Box className={classes.copyrightSeparator}>|</Box>
                  <Button href="https://talisbio.com/terms-of-use/" data-qa="terms-of-use">{formatMessage('talis.termsOfUse')}</Button>
                  <Box className={classes.copyrightSeparator}>|</Box>
                  <Box className={classes.copyrightVersion} data-qa="version">{`v${version}`}</Box>
                </Box>
              </Box>
            </div>
          </Grid>
          <Grid
            item
            xs={false}
            sm={false}
            md={6}
            className={classes.brandingImage}
            data-qa="branding-img"
          />
        </Grid>
      </div>
    </div>
  )
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageLayout
