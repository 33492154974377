import { createTheme } from '@material-ui/core/styles'
import { headerConfig } from '@slipchip/phx-sso-header'

import { mergeDeep } from './index'
/*
  $grey-dark: #444;
  $grey-mid: #666;
  $grey-lite: #aaa;
  $grey-xlite: #ddd;
  $grey-xxlite: #eee;
  $so-white: #fff;
  $atlantic: #1f578d;
  $atlantic-mid: #5b729a;
  $atlantic-soft: #a9bed9;
  $nightingale: #6c79b4;
  $nightingale-soft: #757ea9;
  $nightingale-lite: #b19ff6;
  $casper: #aebbd4;
  $clean-green: #52d733;
  $clay-grey: #b3b0ac;
  $pebble-grey: #8b8784;
  $starling-black: #3e3d3b;
  $watermelon: #f6455c;

  $bg-gradient-1: #7e92b4;
  $bg-gradient-2: #7f79ac;
*/
// Palette
const greyDark = '#444'
const greyMid = '#666'
const greyLite = '#aaa'
const greyXLite = '#ddd'
const greyXXLite = '#eee'
const soWhite = '#fff'
const atlantic = '#1f578d'
const atlanticMid = '#5b729a'
const atlanticSoft = '#a9bed9'
const nightingale = '#6c79b4'
const nightingaleSoft = '#757ea9'
const nightingaleLite = '#b19ff6'
const casper = '#aebbd4'
const cleanGreen = '#52b733'
const clayGrey = '#b3b0ac'
const starlingBlack = '#3e3d3b'
const watermelon = '#f6455c'
const bgGradient1 = '#7e92b4'
const bgGradient2 = '#7f79ac'

const themeOverride = headerConfig.THEME_OVERRIDE || {}

const themes = createTheme(
  mergeDeep(
    {
      palette: {
        error: {
          main: '#e53935',
          light: '#fce7e7',
        },
        background: {
          main: greyXXLite,
          paper: soWhite,
        },
        common: {
          greyDark,
          greyMid,
          greyLite,
          greyXLite,
          greyXXLite,
          soWhite,
          atlantic,
          atlanticMid,
          atlanticSoft,
          nightingale,
          nightingaleSoft,
          nightingaleLite,
          casper,
          cleanGreen,
          clayGrey,
          starlingBlack,
          watermelon,
          bgGradient1,
          bgGradient2,
        },
        primary: {
          main: atlantic,
        },
        secondary: {
          dark: soWhite,
          light: soWhite,
          main: soWhite,
        },
        text: {
          primary: starlingBlack,
          secondary: greyDark,
        },
      },
    },
    themeOverride,
  ),
)

export default themes
