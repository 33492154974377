import React from 'react' // - commented out, to use later for proper form validation: , useEffect } from 'react';

import { Button } from '@material-ui/core'
import { headerConfig } from '@slipchip/phx-sso-header'
import { withStyles } from '../../utils'

const {
  OAUTH_ENDPOINT,
  OAUTH_PROVIDER,
  REDIRECT_URI,
  APP_CLIENT_ID,
  OAUTH_NAME,
} = headerConfig

const OIDCLinks = () => {
  const classes = withStyles()
  // form sso url
  let ssoUrl = `${OAUTH_ENDPOINT}identity_provider=${OAUTH_PROVIDER}`
  ssoUrl = `${ssoUrl}&redirect_uri=${REDIRECT_URI}`
  ssoUrl = `${ssoUrl}&response_type=CODE&client_id=${APP_CLIENT_ID}`

  return (
    <div variant="outlined" className={classes.OIDCLinks}>
      <div className={classes.margin1}>
        <Button
          className={classes.cognitoLoginButton}
          variant="contained"
          color="primary"
          href={ssoUrl}>
          Log In With {`${OAUTH_NAME}`}
        </Button>
      </div>
    </div>
  )
}

export default OIDCLinks
