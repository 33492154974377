/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import clsx from 'clsx'
import { useHistory, useLocation } from 'react-router-dom'
import { axios, headerConfig, CookieStorage } from '@slipchip/phx-sso-header'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  FormHelperText,
  FormLabel,
  Button,
} from '@material-ui/core'
import { formatMessage, useCommonIntl as useIntl } from '@slipchip/common-ui-components';

import { withStyles } from '../../utils'
import PageLayout from '../PageLayout'
import PasswordInput from '../PasswordInput'
import CustomFilledInput from '../FilledInput'


const useQuery = () => new URLSearchParams(useLocation().search)

const ResetForm = () => {
  const cookieStorage = new CookieStorage()
  const history = useHistory()
  const classes = withStyles()
  const query = useQuery()
  const intl = useIntl()

  const queryUserName = query.get('name')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [expired, setExpired] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')
  const [userName, setUserName] = useState(queryUserName)

  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const [passwordErrorText, setPasswordErrorText] = useState('')

  const [password2, setPassword2] = useState('')
  const [password2Error, setPassword2Error] = useState(false)
  const [password2ErrorText, setPassword2ErrorText] = useState('')

  const handleClose = () => {
    // Navigate home when the dialog is closed
    history.push('/')
  }

  const handlePasswordChange = (e) => {
    setPasswordErrorText('')
    setPassword(e.target.value)
  }

  const handlePasswordChange2 = (e) => {
    setPasswordErrorText('')
    setPassword2(e.target.value)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    if (password !== password2) {
      setPassword2ErrorText(
        intl.formatMessage({ id: 'resetForm.confirmNewPassword.validation.mismatch' }),
      )
      return
    }
    const url = `${headerConfig.REDIRECT_URI}/api/users/v1/confirm-forgot-password`
    try {
      const resp = await axios.post(
        url,
        {
          username: userName,
          code: verificationCode,
          password,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      const { data } = resp
      if (data.success) {
        setDialogOpen(true)
      } else if (data.error_code === 400 && data.field_errors) {
        if (data.field_errors.password) {
          setPasswordErrorText(
            intl.formatMessage({ id: `fieldError.validator.${data.field_errors.password.validator}`})
          )
        } else if (data.field_errors.password2) {
          setPassword2Error(true)
          setPassword2ErrorText(
            intl.formatMessage({ id: `fieldError.validator.${data.field_errors.password2.validator}`}),
          )
        }
      } else {
        setExpired(true)
      }
    } catch (err) {
      setPasswordErrorText(err.toString())
    }
  }

  const onResend = async (e) => {
    e.preventDefault()
    const url = `${headerConfig.REDIRECT_URI}/api/users/v1/forgot-password`
    try {
      const resp = await axios.post(
        url,
        {
          username: userName,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      const { data } = resp
      if (data.success) {
        cookieStorage.set('challenge', data.session)
        setExpired(false)
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }

  const VerificationExpiredForm = () => (
    <>
      <FormLabel
        className={clsx(classes.cognitoFormLabel, classes.errorLabel)}>
        {formatMessage('resetForm.verificationExpired.modal.title')}
      </FormLabel>
      <FormHelperText>
        {formatMessage('resetForm.verificationExpired.modal.subtitle')}
      </FormHelperText>
      <CustomFilledInput
        label="Username"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
      />
      <div className={classes.cognitoLoginButtonWrapper}>
        <Button
          type="submit"
          className={classes.cognitoLoginButton}
          data-qa="resend-btn"
          onClick={onResend}>
          {formatMessage('resetForm.verificationExpired.modal.resendBtn')}
        </Button>
        <Button
          type="submit"
          className={classes.cognitoBackButton}
          data-qa="back-btn"
          onClick={() => setExpired(false)}>
          {formatMessage('resetForm.verificationExpired.modal.goBackBtn')}
        </Button>
      </div>
    </>
  );

  const VerificationCodeForm = () => (
    <>
      <FormLabel className={classes.cognitoFormLabel}>
        {formatMessage('resetForm.title')}
        <div>{formatMessage('resetForm.subTitle')}</div>
      </FormLabel>
      <CustomFilledInput
        id="verification-code"
        label={formatMessage('resetForm.verificationCode')}
        value={verificationCode}
        helperText=""
        onChange={(e) => setVerificationCode(e.target.value)}
      />
      <PasswordInput
        id="password"
        password={password}
        label={formatMessage('resetForm.newPassword')}
        onChange={handlePasswordChange}
        error={passwordErrorText !== ''}
        onError={setPasswordError}
        helperText={
          passwordErrorText || formatMessage('resetForm.newPassword.helper')
        }
      />
      <PasswordInput
        id="confirm-password"
        password={password2}
        label={formatMessage('resetForm.confirmNewPassword')}
        onChange={handlePasswordChange2}
        error={password2ErrorText !== ''}
        onError={setPassword2Error}
        helperText={password2ErrorText}
      />
      <div className={classes.cognitoLoginButtonWrapper}>
        <Button
          type="submit"
          className={classes.cognitoLoginButton}
          disabled={
            password === '' ||
            passwordError ||
            password2 === '' ||
            password2Error ||
            verificationCode === ''
          }
          onClick={onSubmit}
          data-qa="reset-btn"
          variant="contained">
          {formatMessage('resetForm.resetBtn')}
        </Button>
      </div>
    </>
  );

  return (
    <PageLayout>
      <div className={classes.CognitoForm}>
        <form className={classes.cognitoLoginForm} noValidate autoComplete="off">
          {expired ? VerificationExpiredForm() : VerificationCodeForm()}
          {!expired && (
            <Dialog
              onClose={handleClose}
              aria-labelledby="resetForm-dialog-title"
              open={dialogOpen}>
              <DialogTitle
                id="resetForm-dialog-title"
                className={classes.registrationDialogTitle}>
                <div>{formatMessage('resetForm.resetSuccess.modal.title')}</div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="resetForm-dialog-description"
                  className={classes.registrationDialogContent}>
                  {formatMessage('resetForm.resetSuccess.modal.subtitle')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <div className={classes.cognitoLoginButtonWrapper}>
                  <Button
                    onClick={handleClose}
                    className={classes.cognitoLoginButton}
                    data-qa="reset-success-btn"
                    autoFocus>
                    {formatMessage('resetForm.resetSuccess.modal.btn')}
                  </Button>
                </div>
              </DialogActions>
            </Dialog>
          )}
        </form>
      </div>
    </PageLayout>
  )
}

export default ResetForm
