/* eslint-disable react/prop-types */
import { React } from 'react'
import {
  FormControl,
  InputLabel,
  FilledInput,
  FormHelperText,
} from '@material-ui/core'
import { withStyles } from '../utils'

const CustomFilledInput = ({
  id,
  value,
  onChange,
  label,
  helperText,
  error,
}) => {
  const classes = withStyles()

  return (
    <div className={classes.cognitoLoginInput}>
      <FormControl fullWidth margin="normal" error={error} variant="filled">
        <InputLabel htmlFor={id} className={classes.cognitoMUIInputLabel}>
          {label}
        </InputLabel>
        <FilledInput
          id={id}
          value={value}
          onChange={onChange}
          className={classes.cognitoMUIInput}
          size="small"
          fullWidth
        />
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </div>
  )
}

export default CustomFilledInput
