/* eslint-disable react/prop-types */
import { React, useState, useEffect } from 'react'
import {
  FormControl,
  IconButton,
  InputLabel,
  FilledInput,
  InputAdornment,
  FormHelperText,
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { withStyles } from '../utils'

const PasswordInput = ({
  id,
  password,
  onChange,
  label,
  helperText,
  error: errorFlag,
  onError,
}) => {
  const classes = withStyles()
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState(errorFlag)

  useEffect(() => {
    setError(errorFlag)
  }, [errorFlag])

  const onInputChange = (event) => {
    onChange(event)
    const val = event.target.value
    const reg =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`])(?=.{8,})/
    const isInvalid = val.match(reg) === null
    setError(isInvalid)
    onError(isInvalid)
  }

  return (
    <div className={classes.cognitoLoginInput}>
      <FormControl fullWidth margin="normal" error={error} variant="filled">
        <InputLabel htmlFor={id} className={classes.cognitoMUIInputLabel}>
          {label}
        </InputLabel>
        <FilledInput
          id={id}
          className={classes.cognitoMUIInput}
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={onInputChange}
          size="small"
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </div>
  )
}

export default PasswordInput
