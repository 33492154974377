import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'

import './App.css'
import { Route, Switch } from 'react-router-dom'

import LanguageProvider from './components/LanguageProviderContainer'
import Dashboard from './components/Dashboard'
import LoginPage from './components/login/LoginPage'
import RegisterPage from './components/registration/RegisterPage'

import theme from './utils/themes'
import PasswordResetPage from './components/passwordReset/ResetPage'
import LogoutPage from './components/logout/LogoutPage'
import PageNotFound from './components/PageNotFound'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LanguageProvider>
        <Switch>
          <Route path="/logged-in">
            <Dashboard />
          </Route>
          <Route exact path="/">
            <LoginPage />
          </Route>
          <Route path="/confirm">
            <RegisterPage />
          </Route>
          <Route path="/reset-password">
            <PasswordResetPage />
          </Route>
          <Route path="/logout">
            <LogoutPage />
          </Route>
          <Route component={PageNotFound} />
        </Switch>
      </LanguageProvider>
    </ThemeProvider>
  )
}

export default App
