import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { axios, headerConfig, CookieStorage } from '@slipchip/phx-sso-header'
import {
  Button,
  FormLabel,
} from '@material-ui/core'
import { formatMessage, useCommonIntl as useIntl } from '@slipchip/common-ui-components';

import PageLayout from '../PageLayout'
import PasswordInput from '../PasswordInput'
import { withStyles } from '../../utils'
import WelcomeDialog from './WelcomeDialog'

const useQuery = () => new URLSearchParams(useLocation().search)

const RegisterForm = () => {
  const cookieStorage = new CookieStorage()
  const intl = useIntl()
  
  // Don't bother rendering the form if we don't have a user id or challenge to register with
  if (!cookieStorage.get('user_id') || !cookieStorage.get('challenge'))
    return null

  const query = useQuery()
  const history = useHistory()
  const classes = withStyles()
  const [user, setUser] = useState({ passcode: '123445' })
  const [dialogOpen, setDialogOpen] = useState(false)

  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const [passwordErrorText, setPasswordErrorText] = useState('')

  const [password2, setPassword2] = useState('')
  const [password2Error, setPassword2Error] = useState(false)
  const [password2ErrorText, setPassword2ErrorText] = useState('')

  const clearErrors = () => {
    setPasswordError(false)
    setPasswordErrorText('')
    setPassword2Error(false)
    setPassword2ErrorText('')
  }

  const onFatalError = () => {
    // remove storage values and redirect back to login
    cookieStorage.remove('challenge')
    cookieStorage.remove('user_id')
    history.push('/')
  }

  const handlePasswordChange = (e) => {
    setPasswordErrorText('')
    setPassword(e.target.value)
  }

  const handlePasswordChange2 = (e) => {
    setPasswordErrorText('')
    setPassword2(e.target.value)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    if (password !== password2) {
      setPassword2ErrorText(intl.formatMessage('resetForm.confirmNewPassword.validation.mismatch'))
      return
    }
    clearErrors()
    const userId = cookieStorage.get('user_id')
    const url = `${headerConfig.REDIRECT_URI}/api/users/v1/${userId}/register`
    try {
      const resp = await axios.post(
        url,
        {
          session: cookieStorage.get('challenge'),
          password,
          password2,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )

      // process the response
      const { data } = resp
      if (data.success) {
        // on success show modal
        setUser(data.user)
        setDialogOpen(true)
      } else if (data.error_code === 400 && data.field_errors) {
        if (data.field_errors.password) {
          setPasswordError(true)
          setPasswordErrorText(
            intl.formatMessage(
              `fieldError.validator.${data.field_errors.password.validator}`,
            ),
          )
        } else if (data.field_errors.password2) {
          setPassword2Error(true)
          setPassword2ErrorText(
            intl.formatMessage({ id:`fieldError.validator.${data.field_errors.password2.validator}`}),
          )
        }
      } else {
        onFatalError()
      }
    } catch (err) {
      onFatalError()
    }
  }

  return (
    <PageLayout>
      <div className={classes.CognitoForm}>
        <form className={classes.cognitoLoginForm} noValidate autoComplete="off">
          <FormLabel className={classes.cognitoFormLabel}>
            {formatMessage('registerForm.greet')}, {query.get('username', '')}!
            <br/>
            {formatMessage('registerForm.greet2')}
          </FormLabel>
          <PasswordInput
            id="password"
            password={password}
            label={formatMessage('resetForm.newPassword')}
            onChange={handlePasswordChange}
            error={passwordErrorText !== ''}
            onError={setPasswordError}
            helperText={
              passwordErrorText || formatMessage('resetForm.newPassword.helper')
            }
          />
          <PasswordInput
            id="confirm-password"
            password={password2}
            label={formatMessage('resetForm.confirmNewPassword')}
            onChange={handlePasswordChange2}
            error={password2ErrorText !== ''}
            onError={setPassword2Error}
            helperText={password2ErrorText}
          />
          <div className={classes.cognitoLoginButtonWrapper}>
            <Button
              type="submit"
              className={classes.cognitoLoginButton}
              disabled={
                password === '' ||
                passwordError ||
                password2 === '' ||
                password2Error
              }
              data-qa="confirm-btn"
              onClick={onSubmit}>
              {formatMessage('registerForm.confirmBtn')}
            </Button>
          </div>
        </form>
        <WelcomeDialog
          open={dialogOpen}
          handleClose={onFatalError}
          user={user}
        />
      </div>
    </PageLayout>
  )
}

export default RegisterForm
