/* eslint-disable react/prop-types */
import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Button,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { formatMessage } from '@slipchip/common-ui-components';
import { withStyles } from '../../utils'

const WelcomeDialog = ({
  open,
  handleClose,
  user,
}) => {
  const classes = withStyles()
  return (
    <Dialog
      className={classes.registrationDialog}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}>
      <DialogTitle
        id="simple-dialog-title"
        className={classes.registrationDialogTitle}>
        <div>
          {formatMessage('registrationComplete.modal.title.line1')}
          <br />
          {formatMessage('registrationComplete.modal.title.line2')}
        </div>
        <DialogContentText
          id="simple-dialog-description"
          className={classes.registrationDialogContent}>
          {formatMessage('registrationComplete.modal.description')}
        </DialogContentText>
      </DialogTitle>
      <DialogContent>
        <div className={classes.passCodeBox}>
          {formatMessage('registrationComplete.modal.passcode')}
          <div>{user.passcode}</div>
        </div>
        <Alert severity="warning" className={classes.registrationDialogAlert}>
          {formatMessage('registrationComplete.modal.alert')}
        </Alert>
      </DialogContent>
      <DialogActions>
        <div className={classes.cognitoLoginButtonWrapper}>
          <Button
            onClick={handleClose}
            className={classes.cognitoLoginButton}
            data-qa="login-page-btn"
            autoFocus>
            {formatMessage('registrationComplete.modal.loginPageBtn')}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default WelcomeDialog
