import { makeStyles } from '@material-ui/core/styles'

export const withStyles = makeStyles((theme) => ({
    App: {
      background: `${theme.palette.whiteLabel.bgColor}`,
      width: '100vw',
      height: '100vh',
      display: 'flex',
    },
    boxShadow: {
      boxShadow: `10px 5px 5px ${theme.palette.common.grey}`,
    },
    brandingImage: {
      backgroundImage: `url(${theme.palette.whiteLabel.brandingImageUrl})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    PageWrapper: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
    },
    LoginPage: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
    },
    formPaper: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 413,
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        alignItems: 'center',
      },
      [theme.breakpoints.up('sm')]: {
        width: 413,
      },
    },
    TalisOneLogoWrapper: {},
    CognitoForm: {
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 0,
    },
    cognitoFormControl: {
      marginRight: 'auto',
      marginLeft: theme.spacing(1),
      minWidth: 200,
    },
    cognitoForgotPasswordButtonWrapper: {
      margin: theme.spacing(2),
      textAlign: 'left',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    cognitoForgotPasswordButton: {
      color: theme.palette.whiteLabel.forgetPassBtn,
      padding: 0,
      textTransform: 'inherit',
    },
    cognitoLoginButtonWrapper: {
      marginTop: '10px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    clientLogoWrapper: {
      width: '100%',
      position: 'absolute',
      left: 0,
      top: 40,
      textAlign: 'left',
      padding: '0 40px',
    },
    clientLogo: {
      width: 118,
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
      },
      [theme.breakpoints.up('md')]: {
        alignItems: 'left',
      },
    },
    talisCloudLogo: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: 30,
    },
    cognitoBackButton: {
      width: '100%',
      display: 'block',
    },
    cognitoLoginButton: {
      borderRadius: 30,
      color: theme.palette.whiteLabel.loginBtnClr,
      height: '3rem',
      fontSize: '.8125rem',
      backgroundColor: theme.palette.whiteLabel.loginBtnBgActive,
      '&:hover': {
        backgroundColor: theme.palette.whiteLabel.loginBtnBgActive,
      },
      '&:active': {
        backgroundColor: theme.palette.whiteLabel.loginBtnBgActive,
      },
      '&:disabled': {
        color: theme.palette.whiteLabel.loginBtnInactiveClr,
      },
      minWidth: '11rem',
      maxWidth: '100%',
      zIndex: 10,
    },
    cognitoLoginForm: {},
    cognitoLoginInput: {
      borderRadius: 5,
    },
    cognitoMUIInputLabel: {
      color: '#afa9a9',
    },
    cognitoMUIInput: {
      backgroundColor: theme.palette.common.soWhite,
      borderRadius: 5,
      '&:hover': {
        backgroundColor: 'rgba(106, 115, 157, 0.2)',
        color: theme.palette.whiteLabel.fieldTxtColor,
        '& button': {
          color: '#afa9a9',
        },
      },
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    margin1: {
      margin: theme.spacing(1),
    },
    marginAuto: {
      margin: 'auto',
    },
    OIDCLinks: {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      boxShadow: `10px 5px 5px ${theme.palette.common.grey}`,
    },
    padding4: {
      padding: theme.spacing(4),
    },
    right: {
      marginLeft: 'auto',
      marginRight: theme.spacing(1),
    },
    cognitoFormLabel: {
      display: 'inline-block',
      width: '100%',
      textAlign: 'left',
      fontSize: 'larger',
      lineHeight: '25px',
      '& div': {
        fontSize: 'medium',
        color: theme.palette.common.greyMid,
      }
    },
    errorLabel: {
      color: theme.palette.error.main,
    },
    cognitoErrorSnackbar: {
      '& div': {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.error.main,
      },
      position: 'relative',
      bottom: '-30px',
    },
    registrationDialogTitle: {
      textAlign: 'left',
      '& div': {
        marginBottom: '5px',
      },
    },
    registrationDialog: {
      '& div[role=dialog]': {
        maxWidth: '400px',
      },
    },
    registrationDialogContent: {
      fontSize: 'small',
      textAlign: 'left',
    },
    registrationDialogAlert: {
      fontSize: 'small',
    },
    passCodeBox: {
      textAlign: 'center',
      margin: '0px auto 40px auto',
      maxWidth: '200px',
      fontSize: 'small',
      borderRadius: '5px',
      backgroundColor: theme.palette.common.greyXXLite,
      padding: '20px',
      '& div': {
        paddingTop: '15px',
        fontSize: 'xx-large',
        fontWeight: 'bold',
      },
    },
    copyrightWrapper: {
      marginBottom: 16,
      fontSize: 14,
      fontFamily: 'Mulish',
      lineHeight: '24px',
      '& .MuiButton-root': {
        textTransform: 'none',
      }
    },
    copyrightSeparator: {
      display: 'inline',
      paddingLeft: 5,
      paddingRight: 5,
    },
    copyrightVersion: {
      display: 'inline',
      marginLeft: 10,
    }
  })
)

export const isObject = (item) =>
  item && typeof item === 'object' && !Array.isArray(item)

export const mergeDeep = (target, ...sources) => {
  if (!sources.length) return target
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} })
        mergeDeep(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    })
  }
  return mergeDeep(target, ...sources)
}

export default withStyles
