import clsx from 'clsx'
import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { useLocation, useHistory } from 'react-router-dom'
import Header, { CookieStorage } from '@slipchip/phx-sso-header'

import { withStyles } from '../utils'

const headerStorage = new CookieStorage()
const useQuery = () => new URLSearchParams(useLocation().search)
const Dashboard = () => {
  const classes = withStyles()
  const query = useQuery()
  const code = query.get('code')
  const history = useHistory()
  if (!code && !headerStorage.get('accessToken')) {
    history.push('/')
  }
  return (
    <div className={clsx(classes.App, classes.flexColumn)}>
      <Header clearCode render={false} />
      <div className={classes.marginAuto}>
        <CircularProgress color="secondary" size={80} />
      </div>
    </div>
  )
}

export default Dashboard
